import React from "react";
import { Modal, Form, Input } from "antd";
import { execSendAdmitCard } from "../../../api/adminApis";
import { notifyError, notifySuccess } from "../../../helper/notifiers";

export default function SendAdmitCard(props) {
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const onOk = async (val) => {
    setLoading(true);
    try {
      localStorage.setItem("admit_card", JSON.stringify(val));
      const res = await execSendAdmitCard(props.id, val);
      notifySuccess(res.message);
      props.onCancel(true);
      setLoading(false);
    } catch (err) {
      notifyError(err.message);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    let getDetails = localStorage.getItem("admit_card");
    if (getDetails) {
      getDetails = JSON.parse(getDetails);
      form.setFieldsValue({ ...getDetails });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <Modal
        title="Send Admit Card"
        visible={props.visible}
        maskClosable={false}
        centered
        onCancel={() => {
          props.onCancel();
        }}
        onOk={() => {
          form.submit();
        }}
        okButtonProps={{ loading: loading }}
        cancelButtonProps={{}}
      >
        <Form form={form} onFinish={onOk}>
          <Form.Item label="Exam Date" name="exam_held_on" className="full" rules={[{ required: true, message: "Exam date is required" }]}>
            <Input placeholder="Friday, 11 August 2020 at 7:00 AM" />
          </Form.Item>

          <Form.Item label="Exam Center" name="exam_center" className="full" rules={[{ required: true, message: "Exam center is required" }]}>
            <Input placeholder="SOMTU, Kritipur" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
