import React from "react";
import { Menu, Layout } from "antd";
import { NavLink } from "react-router-dom";
import { GoldOutlined, StarOutlined } from "@ant-design/icons";

const sliderItems = [
  { goto: "/somtuadmin", text: "Entrance Form", icon: <GoldOutlined /> },
  { goto: "/somtuadmin/form_status", text: "Form Status", icon: <StarOutlined /> },
  { goto: "/somtuadmin/vouchers", text: "Voucher Request", icon: <GoldOutlined /> },
  { goto: "/somtuadmin/voucher_status", text: "Voucher Status", icon: <GoldOutlined /> },
];

export default function HomeSideNav(props) {
  return (
    <Layout hasSider>
      <Layout.Sider
        theme="dark"
        trigger={null}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          if (broken) {
            props.setSliderCollapsed(true);
          } else {
            props.setSliderCollapsed(false);
          }
        }}
        onCollapse={() => {
          props.setSliderCollapsed(!props.sliderCollapsed);
        }}
        collapsedWidth={0}
        width={250}
        collapsed={props.sliderCollapsed}
        // collapsible={true}
      >
        <Menu style={{ minHeight: "100vh", fontWeight: "700" }} mode="inline" theme="dark">
          <div className="logo">
            <img src="/admin_logo.png" alt="" />
          </div>
          <ul className="sidenavs-items">
            {sliderItems.map((item, index) => (
              <li key={index}>
                <NavLink exact to={item.goto} activeClassName="on">
                  {item.icon}
                  <span className="item-name">{item.text}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </Menu>
      </Layout.Sider>
    </Layout>
  );
}
