import React from "react";
import { Button, Tag, Modal } from "antd";
import { execGetPrograms, execUpdatePrograms } from "../../../api/adminApis";
import Loading from "../../../components/Loading";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { notifySuccess, notifyError } from "../../../helper/notifiers";

export default function FormStatus() {
  const [state, setState] = React.useState({
    loading: true,
    data: null,
    error: false,
  });

  const fetchProgram = async () => {
    setState({ data: null, error: false, loading: true });
    try {
      const res = await execGetPrograms();
      setState({ loading: false, data: res, error: false });
    } catch (err) {
      setState({ loading: false, data: null, error: err });
    }
  };

  React.useEffect(() => {
    fetchProgram();
    //eslint-disable-next-line
  }, []);

  return (
    <section id="contents">
      <br />
      <h1 className="title"> Form Status | Available Programs </h1>
      <br />
      {state.loading && <Loading />}
      {state.error && <p style={{ color: "red" }}>{state.error.message}</p>}
      {state.data && state.data.length > 0 && (
        <React.Fragment>
          <div className="contents-table">
            <table cellPadding="0" cellSpacing="0">
              <ContentTableHead />
              <tbody>
                {state.data.map((item, index) => {
                  return <ContentTableItems refresh={fetchProgram} key={item._id} {...item} sn={index + 1} />;
                })}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
    </section>
  );
}

const ContentTableHead = () => {
  return (
    <thead>
      <tr>
        <th style={{ maxWidth: "40px" }}>SN</th>
        <th>Program Name</th>
        <th>Form Opened</th>
        <th style={{ maxWidth: "200px" }}>Actions</th>
      </tr>
    </thead>
  );
};

const ContentTableItems = (props) => {
  const onChange = (val) => {
    Modal.confirm({
      title: "Are you sure?",
      content: "You want to " + (val ? "open" : "close") + " form for this program?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await execUpdatePrograms(props._id, { form_open: val });
          notifySuccess(res.message);
          props.refresh();
        } catch (err) {
          notifyError(err.message);
        }
      },
    });
  };

  return (
    <tr>
      <td>{props.sn}</td>
      <td>{props.name}</td>
      <td>{props.form_open === true ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}</td>
      <td className="actions" style={{ padding: 10 }}>
        <Button onClick={() => onChange(true)} type="primary" size="small">
          Open
        </Button>
        <p style={{ height: 5 }}>&nbsp;</p>
        <Button onClick={() => onChange(false)} type="danger" size="small">
          Close
        </Button>
      </td>
    </tr>
  );
};
