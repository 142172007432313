import React from "react";
import { Button, Form, Input } from "antd";

export default function Referees({ onNext, onPrevious, inputs }) {
  const [form] = Form.useForm();

  const onOk = (val) => {
    onNext(val);
  };

  React.useEffect(() => {
    form.setFieldsValue({ ...inputs });
    // eslint-disable-next-line
  }, [inputs]);

  return (
    <Form onFinish={onOk} form={form}>
      <header>
        <h1>
          <b>Referees</b>
        </h1>
        <p>Write down name of two reputed person who would know you outside the family.</p>
      </header>
      <br />
      <header>
        <h2>
          <b>Person One</b>
        </h2>
      </header>
      <div className="two-item">
        <Form.Item
          label="Name"
          className="full"
          name="referee_one_name"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        >
          <Input placeholder="eg. Ram Prasad Neupane" />
        </Form.Item>
        <Form.Item
          label="Contact Phone"
          className="full"
          name="referee_one_phone"
          rules={[
            {
              required: true,
              message: "Contact phone is required",
            },
          ]}
        >
          <Input placeholder="eg. 98420*****" />
        </Form.Item>
      </div>
      <div className="two-item">
        <Form.Item
          label="Institution"
          className="full"
          name="referee_one_institution"
          rules={[
            {
              required: true,
              message: "Institution is required",
            },
          ]}
        >
          <Input placeholder="eg. Bank" />
        </Form.Item>
        <Form.Item
          label="Position"
          className="full"
          name="referee_one_position"
          rules={[
            {
              required: true,
              message: "Position is required",
            },
          ]}
        >
          <Input placeholder="eg. Manager" />
        </Form.Item>
      </div>

      <br />
      <header>
        <h2>
          <b>Person Two</b>
        </h2>
      </header>
      <div className="two-item">
        <Form.Item
          label="Name"
          className="full"
          name="referee_two_name"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        >
          <Input placeholder="eg. Ram Prasad Neupane" />
        </Form.Item>
        <Form.Item
          label="Contact Phone"
          className="full"
          name="referee_two_phone"
          rules={[
            {
              required: true,
              message: "Phone is required",
            },
          ]}
        >
          <Input placeholder="eg. 98420*****" />
        </Form.Item>
      </div>
      <div className="two-item">
        <Form.Item
          label="Institution"
          className="full"
          name="referee_two_institution"
          rules={[
            {
              required: true,
              message: "Institution is required",
            },
          ]}
        >
          <Input placeholder="eg. Bank" />
        </Form.Item>
        <Form.Item
          label="Position"
          className="full"
          name="referee_two_position"
          rules={[
            {
              required: true,
              message: "Position is required",
            },
          ]}
        >
          <Input placeholder="eg. Manager" />
        </Form.Item>
      </div>

      <br />
      <div className="flex ci">
        <Button onClick={() => onPrevious(form.getFieldsValue())} style={{ width: 200 }}>
          Previous
        </Button>
        <div className="hgap"></div>
        <Button htmlType="submit" style={{ width: 200 }} type="primary">
          Next
        </Button>
      </div>
    </Form>
  );
}
