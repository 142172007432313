import React from "react";
import SubPageHead from "../../components/SubPageHead/SubPageHead";

export default function Notices() {
  return (
    <div>
      <SubPageHead title="Notices" />
      <br /> <br />
      <div className="wrap">
        <div style={{ textAlign: "center" }}>
          <img
            style={{ width: 600, maxWidth: "90%" }}
            src="/notice1.png"
            alt=""
          />
        </div>
        <br />
        <br />
        <div style={{ textAlign: "center" }}>
          <img
            style={{ width: 600, maxWidth: "90%" }}
            src="/notice2.png"
            alt=""
          />
        </div>
        <br />
        <br />
        <div style={{ textAlign: "center" }}>
          <img
            style={{ width: 600, maxWidth: "90%" }}
            src="/notice3.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
