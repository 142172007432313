import React from "react";
import Loading from "../../../components/Loading";
import { getStatusColor } from "../../../helper/getColor";
import parse from "html-react-parser";
import { FILE_BASE_URL, BASE_URL } from "../../../configs";
import { Button, Form, Input, Select, InputNumber } from "antd";
import { execReadMyForm } from "../../../api/apis";
import queryString from "query-string";
import SubPageHead from "../../../components/SubPageHead/SubPageHead";
import { Link } from "react-router-dom";

export default function ReadFormStatus() {
  const [state, setState] = React.useState({
    data: null,
    loading: false,
    error: false,
  });
  const [form] = Form.useForm();

  const fetchForm = async (payload) => {
    setState({ loading: true, data: null, error: false });
    try {
      const res = await execReadMyForm(payload);
      setState({ data: res, loading: false, error: false });
    } catch (err) {
      setState({ data: null, loading: false, error: err });
    }
  };

  //http://localhost:3000/form_status?dob=1999-04-25&dob_type=AD&surname=neeupane&identification_number=1006

  React.useEffect(() => {
    const { query } = queryString.parseUrl(window.location.href);
    form.setFieldsValue({ dob_type: "BS", ...query });
    const { dob, dob_type, identification_number, surname } = query;
    if (dob && dob_type && identification_number && surname) {
      form.submit();
    }
    //eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <SubPageHead title="Check your form status" />
      <br />
      <div className="wrap">
        <div className="fields">
          <Form form={form} onFinish={fetchForm}>
            <div className="four-item">
              <Form.Item
                label="Identification Number"
                className="full"
                name="identification_number"
                rules={[
                  {
                    required: true,
                    message: "Identification Number is required",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} placeholder="eg. 9999" />
              </Form.Item>

              <Form.Item
                label="Last name"
                className="full"
                name="surname"
                rules={[
                  {
                    required: true,
                    message: "Last name is required",
                  },
                ]}
              >
                <Input placeholder="eg. Neupane" />
              </Form.Item>
              <Form.Item
                label="Date of birth type"
                className="full"
                name="dob_type"
                rules={[
                  {
                    required: true,
                    message: "DOB type is required",
                  },
                ]}
              >
                <Select placeholder="Select Type">
                  <Select.Option value="BS">BS</Select.Option>
                  <Select.Option value="AD">AD</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Date of birth | YYYY-MM-DD"
                className="full"
                name="dob"
                rules={[
                  {
                    required: true,
                    message: "DOB is required",
                  },
                ]}
              >
                <Input placeholder="eg. 1998-04-25" />
              </Form.Item>
            </div>
            <br />
            <Button htmlType="submit" type="primary">
              Search Form
            </Button>
          </Form>
        </div>
        <br />
        {state.loading && <Loading />}
        {state.error && <p style={{ color: "red" }}>{state.error.message || "Unusual error occured"}</p>}
        {state.data && (
          <main className="entrance-form-view">
            {state.data.status === "Approved" && state.data.voucher_status !== "Not Available" && (
              <main>
                <div className="flex jcsb ci">
                  <h2>
                    <b>Voucher Details</b>
                  </h2>
                  <h2>
                    <b>
                      Status: <span style={{ color: getStatusColor(state.data.voucher_status) }}>{state.data.voucher_status}</span>
                    </b>
                    {state.data.voucher_rejection_reason && (
                      <React.Fragment>
                        <br />
                        <span style={{ fontSize: 11 }}>
                          <b>Rejection Reason: </b>
                          {state.data.voucher_rejection_reason}
                        </span>
                      </React.Fragment>
                    )}
                  </h2>
                </div>
                <div className="card">
                  <div className="three-item">
                    <p>
                      <b>Bank: </b> {(state.data.voucher && state.data.voucher.bank) || "n/a"}
                    </p>
                    <p>
                      <b>Branch: </b> {(state.data.voucher && state.data.voucher.branch) || "n/a"}
                    </p>
                    <p>
                      <b>Amount: </b> {(state.data.voucher && state.data.voucher.amount) || "n/a"}
                    </p>
                    <p>
                      <b>Paid By: </b> {(state.data.voucher && state.data.voucher.paid_by) || "n/a"}
                    </p>
                    <p>
                      <b>Date of payment: </b> {(state.data.voucher && state.data.voucher.date_of_payment) || "n/a"}
                    </p>
                  </div>
                </div>
                <br />
                <h2>
                  <b>Voucher Photo</b>
                </h2>
                <FileItem link={(state.data.voucher && state.data.voucher.url) || ""} title="Voucher Photo" />
                <br />
                {state.data.admit_card_status === "Sent" && (
                  <React.Fragment>
                    <h2>
                      <b>Actions</b>
                    </h2>
                    <div className="hgap"></div>
                    <a target="_blank" rel="noopener noreferrer" href={BASE_URL + "/v1/admit_card?identification_number=" + state.data.identification_number + "&form_id=" + state.data._id}>
                      <Button type="primary">See Admit Card</Button>
                    </a>
                    <br /> <br />
                  </React.Fragment>
                )}
              </main>
            )}

            {state.data.status === "Approved" && (state.data.voucher_status === "Not Available" || state.data.voucher_status === "Rejected") && (
              <React.Fragment>
                <br />
                <h2>
                  <b>Actions</b>
                </h2>
                <Link to={"/submit_voucher?identification_number=" + state.data.identification_number + "&form_id=" + state.data._id}>
                  <Button type="primary">Submit Voucher Details</Button>
                </Link>
                {state.data.admit_card_status === "Sent" && (
                  <React.Fragment>
                    <a target="_blank" rel="noopener noreferrer" href={BASE_URL + "/v1/admit_card?identification_number=" + state.data.identification_number + "&form_id=" + state.data._id}>
                      <Button type="primary">Download Admit Card</Button>
                    </a>
                  </React.Fragment>
                )}
                <br /> <br />
              </React.Fragment>
            )}

            <div className="flex jcsb ci">
              <h2>
                <b>General Details</b>
              </h2>
              <h2>
                <b>
                  Status: <span style={{ color: getStatusColor(state.data.status) }}>{state.data.status}</span>
                </b>
                {state.data.rejection_reason && (
                  <React.Fragment>
                    <br />
                    <span style={{ fontSize: 11 }}>
                      <b>Rejection Reason: </b>
                      {state.data.rejection_reason}
                    </span>
                  </React.Fragment>
                )}
              </h2>
            </div>
            <div className="card">
              <div className="three-item">
                <p>
                  <b>First Name: </b> {state.data.first_name}
                </p>
                <p>
                  <b>Middle Name: </b> {state.data.middle_name}
                </p>
                <p>
                  <b>Last Name: </b> {state.data.surname}
                </p>

                <p>
                  <b>Applying For: </b> {state.data.applying_for}
                </p>
                <p>
                  <b>Category: </b> {state.data.category}
                </p>
                <p></p>
                <p>
                  <b>Gender: </b> {state.data.gender}
                </p>
                <p>
                  <b>Marital Status: </b> {state.data.marital_status}
                </p>
                <p></p>
                <p>
                  <b>DOB (AD): </b> {state.data.dob_ad}
                </p>
                <p>
                  <b>DOB (BS): </b> {state.data.dob_bs}
                </p>
                <p></p>
                <p>
                  <b>Guardian Name: </b> {state.data.guardian_name}
                </p>
                <p>
                  <b>Guardian Profession: </b> {state.data.guardian_profession}
                </p>
                <p></p>
                <p>
                  <b>Citizenship Number: </b> {state.data.citizenship_no}
                </p>
                <p>
                  <b>Permanent Address: </b> {state.data.permanent_address}
                </p>
                <p>
                  <b>Ktm Contact Address: </b> {state.data.ktm_contact_address}
                </p>

                <p>
                  <b>Contact Mobile: </b> {state.data.contact_mobile}
                </p>

                <p>
                  <b>Contact Landline: </b> {state.data.contact_ladline}
                </p>

                <p>
                  <b>Email: </b> {state.data.email}
                </p>
              </div>
            </div>
            <br />
            <h2>
              <b>Academic Report (Bachelor)</b>
            </h2>
            <div className="card">
              <div className="three-item">
                <p>
                  <b>Board/University: </b>
                  {state.data.bachelor_board}
                </p>
                <p>
                  <b>Degree: </b>
                  {state.data.bachelor_degree}
                </p>
                <p>
                  <b>Duration: </b>
                  {state.data.bachelor_duration}
                </p>
                <p>
                  <b>Specialization: </b>
                  {state.data.bachelor_specialization}
                </p>
                <p>
                  <b>Grade: </b>
                  {state.data.bachelor_grade}
                </p>
                <p>
                  <b>Date of award: </b>
                  {state.data.bachelor_doa}
                </p>
              </div>
            </div>

            <br />
            <h2>
              <b>Academic Report (+2 / A level)</b>
            </h2>
            <div className="card">
              <div className="three-item">
                <p>
                  <b>Board/University: </b>
                  {state.data.plus2_board}
                </p>
                <p>
                  <b>Degree: </b>
                  {state.data.plus2_degree}
                </p>
                <p>
                  <b>Duration: </b>
                  {state.data.plus2_duration}
                </p>
                <p>
                  <b>Specialization: </b>
                  {state.data.plus2_specialization}
                </p>
                <p>
                  <b>Grade: </b>
                  {state.data.plus2_grade}
                </p>
                <p>
                  <b>Date of award: </b>
                  {state.data.plus2_doa}
                </p>
              </div>
            </div>

            <br />
            <h2>
              <b>Academic Report (SLC)</b>
            </h2>
            <div className="card">
              <div className="three-item">
                <p>
                  <b>Board/University: </b>
                  {state.data.slc_board}
                </p>
                <p>
                  <b>Degree: </b>
                  {state.data.slc_degree}
                </p>
                <p>
                  <b>Duration: </b>
                  {state.data.slc_duration}
                </p>
                <p>
                  <b>Specialization: </b>
                  {state.data.slc_specialization}
                </p>
                <p>
                  <b>Grade: </b>
                  {state.data.slc_grade}
                </p>
                <p>
                  <b>Date of award: </b>
                  {state.data.slc_doa}
                </p>
              </div>
            </div>

            <br />
            <h2>
              <b>Academic Report (Extra)</b>
            </h2>
            <div className="card">
              <div className="three-item">
                <p>
                  <b>Board/University: </b>
                  {state.data.extra_board}
                </p>
                <p>
                  <b>Degree: </b>
                  {state.data.extra_degree}
                </p>
                <p>
                  <b>Duration: </b>
                  {state.data.extra_duration}
                </p>
                <p>
                  <b>Specialization: </b>
                  {state.data.extra_specialization}
                </p>
                <p>
                  <b>Grade: </b>
                  {state.data.extra_grade}
                </p>
                <p>
                  <b>Date of award: </b>
                  {state.data.extra_doa}
                </p>
              </div>
            </div>

            <br />
            <h2>
              <b>Financial Support</b>
            </h2>
            <div className="card">{parse(state.data.financial_support || "")}</div>

            <br />
            <h2>
              <b>Referee (First)</b>
            </h2>
            <div className="card">
              <div className="two-item">
                <p>
                  <b>Name: </b> {state.data.referee_one_name}
                </p>
                <p>
                  <b>Phone: </b> {state.data.referee_one_phone}
                </p>
                <p>
                  <b>Institution: </b> {state.data.referee_one_institution}
                </p>
                <p>
                  <b>Position: </b> {state.data.referee_one_position}
                </p>
              </div>
            </div>

            <br />
            <h2>
              <b>Referee (Second)</b>
            </h2>
            <div className="card">
              <div className="two-item">
                <p>
                  <b>Name: </b> {state.data.referee_two_name}
                </p>
                <p>
                  <b>Phone: </b> {state.data.referee_two_phone}
                </p>
                <p>
                  <b>Institution: </b> {state.data.referee_two_institution}
                </p>
                <p>
                  <b>Position: </b> {state.data.referee_two_position}
                </p>
              </div>
            </div>

            <br />
            <h2>
              <b>Why SOMTU</b>
            </h2>
            <div className="card">{parse(state.data.why_somtu || "")}</div>

            <br />
            <h2>
              <b>Documents</b>
            </h2>
            <div className="card">
              <FileItem link={state.data.pp_photo} title="Passport Size Photo" />
              <FileItem link={state.data.citizenship_front} title="Citizenship Front" />
              <FileItem link={state.data.citizenship_back} title="Citizenship Back" />
              <FileItem link={state.data.slc_marksheet} title="SLC Marksheet" />
              <FileItem link={state.data.slc_cc} title="SLC Character Certificate" />
              <FileItem link={state.data.plus2_marksheet} title="+2/A level Marksheet" />
              <FileItem link={state.data.plus2_cc} title="+2/A level Character Certificate" />
              <FileItem link={state.data.bachelor_marksheet} title="Bachelor Marksheet" />
              <FileItem link={state.data.bachelor_cc} title="Bachelor Character Certificate" />
              <FileItem link={state.data.bachelor_mc} title="Bachelor Migration Certificate" />
              <FileItem link={state.data.selfi_with_citizenship} title="Selfie With Citizenship" />
              <FileItem link={state.data.tu_equivalence} title="TU Equivalence" />
            </div>
            <br />
            <br />
            <br />
          </main>
        )}
      </div>
    </React.Fragment>
  );
}

const FileItem = ({ link, title }) => {
  const getLink = () => {
    if (!link) return "/pdf.png";
    const ext = link.substr(link.length - 3);
    if (ext === "pdf" || ext === "PDF") return "/pdf.png";
    return FILE_BASE_URL + link;
  };
  if (!link) return null;
  return (
    <div className="file-item">
      <img style={{ width: 60, height: 60, objectFit: "cover", borderRadius: 4 }} src={getLink()} alt="" />
      <br />
      <a href={FILE_BASE_URL + link} rel="noopener noreferrer" target="_blank">
        {title}
      </a>
    </div>
  );
};
