import React from "react";
import { Steps } from "antd";
import GeneralDetails from "./GeneralDetails";
import AcademicRecord from "./AcademicRecord";
import FinancialSupport from "./FinancialSupport";
import Referees from "./Referees";
import WhySomtu from "./WhySomtu";
import RequiredDocuments from "./RequiredDocuments";
import { notifyError, notifySuccess } from "../../../helper/notifiers";
import Loading from "../../../components/Loading";
import { execGetOneEntraceForm, execUpdateForm } from "../../../api/adminApis";

export default function EntranceFormEdit({ history, match }) {
  const eId = match.params.id;
  const [state, setState] = React.useState({
    data: null,
    loading: false,
    error: false,
  });

  const fetchForm = async () => {
    setState({ loading: true, data: null, error: false });
    try {
      const res = await execGetOneEntraceForm(eId);
      setState({ data: res, loading: false, error: false });
      setInputs(res);
    } catch (err) {
      setState({ data: null, loading: false, error: err });
    }
  };

  const [inputs, setInputs] = React.useState({});
  const [step, setStep] = React.useState(0);
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const addInputs = (val) => {
    setInputs({ ...inputs, ...val });
    setStep((pS) => pS + 1);
  };

  const onPrevious = (val) => {
    if (step === 0) return;
    setInputs({ ...inputs, ...val });
    setStep((pS) => pS - 1);
  };

  const onSubmitForm = async (files) => {
    const payload = { ...inputs, ...files };
    setSubmitLoading(true);
    try {
      const res = await execUpdateForm(eId, payload);
      notifySuccess(res.message);
      setSubmitLoading(false);
      history.push("/somtuadmin/entrance_form/" + eId);
    } catch (err) {
      notifyError(err.message);
      setSubmitLoading(false);
    }
  };

  React.useEffect(() => {
    fetchForm();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="entry-form">
      {state.loading && <Loading />}
      {state.data && (
        <React.Fragment>
          <br />
          <div className="abcwrap">
            <header>
              <h1>
                <b>Entrance Form Edit</b>
              </h1>
            </header>
            <br />
            <main>
              <Steps size="small" current={step}>
                <Steps.Step title="General Details"></Steps.Step>
                <Steps.Step title="Academic Record"></Steps.Step>
                <Steps.Step title="Financial Support"></Steps.Step>
                <Steps.Step title="Referees"></Steps.Step>
                <Steps.Step title="Why SOMTU"></Steps.Step>
                <Steps.Step title="Required Documents"></Steps.Step>
              </Steps>
              <br />
              <div className="content">
                {step === 0 && <GeneralDetails data={state.data} inputs={inputs} onNext={addInputs} />}
                {step === 1 && <AcademicRecord inputs={inputs} onNext={addInputs} onPrevious={onPrevious} />}
                {step === 2 && <FinancialSupport inputs={inputs} onNext={addInputs} onPrevious={onPrevious} />}
                {step === 3 && <Referees inputs={inputs} onNext={addInputs} onPrevious={onPrevious} />}
                {step === 4 && <WhySomtu inputs={inputs} onNext={addInputs} onPrevious={onPrevious} />}
                {step === 5 && <RequiredDocuments inputs={inputs} submitLoading={submitLoading} onSubmit={onSubmitForm} onPrevious={onPrevious} />}
              </div>
            </main>
          </div>
          <br />
          <br />
          <br />
        </React.Fragment>
      )}
    </div>
  );
}
