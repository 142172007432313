import React from 'react'
import {
	execGetOneEntraceForm,
	execUpdateEntraceForm,
} from '../../../api/adminApis'
import Loading from '../../../components/Loading'
import { getStatusColor } from '../../../helper/getColor'
import parse from 'html-react-parser'
import { FILE_BASE_URL } from '../../../configs'
import { Button, Modal } from 'antd'
import {
	HomeOutlined,
	ExclamationCircleOutlined,
	EditOutlined,
} from '@ant-design/icons'
import { BreadCrumbBuilder } from '../../../components/BreadCrumbBuilder'
import RejectModal from './RejectModal'
import { notifySuccess, notifyError } from '../../../helper/notifiers'
import { Link } from 'react-router-dom'
import DocCarousel from './DocCarousel'
import { getLink } from '../../../helper/getLink'

const bCrumb = [
	{ goto: '/admin', text: <HomeOutlined /> },
	{ text: 'Entrance Form', goto: '/admin' },
	{ text: 'View Entrance Form' },
]

export default function EntranceFormDetails({ match }) {
	const [showReject, setShowReject] = React.useState(false)
	const [loggedName, setLoggedName] = React.useState(null)

	const eId = match.params.id
	const [state, setState] = React.useState({
		data: null,
		loading: false,
		error: false,
	})

	const fetchForm = async () => {
		setState({ loading: true, data: null, error: false })
		try {
			const res = await execGetOneEntraceForm(eId)
			setState({ data: res, loading: false, error: false })
		} catch (err) {
			setState({ data: null, loading: false, error: err })
		}
	}

	React.useEffect(() => {
		fetchForm()
		let adminName = localStorage.getItem('admin_data')
		if (adminName) adminName = JSON.parse(adminName).username
		setLoggedName(adminName || '')
		//eslint-disable-next-line
	}, [])

	const onApprove = () => {
		Modal.confirm({
			title: 'Are you sure?',
			content: 'You want to approve this form?',
			icon: <ExclamationCircleOutlined />,
			okText: 'Yes',
			okType: 'danger',
			cancelText: 'No',
			onOk: async () => {
				try {
					const res = await execUpdateEntraceForm(eId, {
						status: 'Approved',
					})
					notifySuccess(res.message)
					fetchForm()
				} catch (err) {
					notifyError(err.message)
				}
			},
		})
	}

	const onDelete = () => {
		Modal.confirm({
			title: 'Are you sure?',
			content: 'You want to delete this form?',
			icon: <ExclamationCircleOutlined />,
			okText: 'Yes',
			okType: 'danger',
			cancelText: 'No',
			onOk: async () => {
				try {
					const res = await execUpdateEntraceForm(eId, {
						status: 'Removed',
					})
					notifySuccess(res.message)
					fetchForm()
				} catch (err) {
					notifyError(err.message)
				}
			},
		})
	}

	return (
		<div>
			<br />
			<RejectModal
				visible={showReject}
				id={eId}
				onCancel={(refresh = false) => {
					setShowReject(false)
					if (refresh === true) fetchForm()
				}}
			/>
			<BreadCrumbBuilder data={bCrumb} />
			<br />
			{state.loading && <Loading />}
			{state.error && (
				<p style={{ color: 'red' }}>{JSON.stringify(state.error)}</p>
			)}
			{state.data && (
				<main className="entrance-form-view">
					{loggedName === 'finance' && (
						<React.Fragment>
							<Link to={'/somtuadmin/entrance_form_edit/' + eId}>
								<Button type="primary" icon={<EditOutlined />}>
									Edit Form
								</Button>
							</Link>
							<br /> <br />
						</React.Fragment>
					)}
					<div className="flex jcsb ci">
						<h2>
							<b>General Details </b>
						</h2>
						<h2>
							<b>
								Status:{' '}
								<span
									style={{
										color: getStatusColor(
											state.data.status
										),
									}}
								>
									{state.data.status}
								</span>
							</b>
							{state.data.rejection_reason && (
								<React.Fragment>
									<br />
									<span style={{ fontSize: 11 }}>
										<b>Rejection Reason: </b>
										{state.data.rejection_reason}
									</span>
								</React.Fragment>
							)}
						</h2>
					</div>
					<div className="card">
						<div className="three-item">
							<p>
								<b>First Name: </b> {state.data.first_name}
							</p>
							<p>
								<b>Middle Name: </b> {state.data.middle_name}
							</p>
							<p>
								<b>Surname: </b> {state.data.surname}
							</p>

							<p>
								<b>Applying For: </b> {state.data.applying_for}
							</p>
							<p>
								<b>Category: </b> {state.data.category}
							</p>
							<p></p>
							<p>
								<b>Gender: </b> {state.data.gender}
							</p>
							<p>
								<b>Marital Status: </b>{' '}
								{state.data.marital_status}
							</p>
							<p></p>
							<p>
								<b>DOB (AD): </b> {state.data.dob_ad}
							</p>
							<p>
								<b>DOB (BS): </b> {state.data.dob_bs}
							</p>
							<p></p>
							<p>
								<b>Guardian Name: </b>{' '}
								{state.data.guardian_name}
							</p>
							<p>
								<b>Guardian Profession: </b>{' '}
								{state.data.guardian_profession}
							</p>
							<p></p>
							<p>
								<b>Citizenship Number: </b>{' '}
								{state.data.citizenship_no}
							</p>
							<p>
								<b>Permanent Address: </b>{' '}
								{state.data.permanent_address}
							</p>
							<p>
								<b>Ktm Contact Address: </b>{' '}
								{state.data.ktm_contact_address}
							</p>

							<p>
								<b>Contact Mobile: </b>{' '}
								{state.data.contact_mobile}
							</p>

							<p>
								<b>Contact Landline: </b>{' '}
								{state.data.contact_ladline}
							</p>

							<p>
								<b>Email: </b> {state.data.email}
							</p>
						</div>
					</div>
					<DocCarousel
						docs={[
							{
								id: 'citizenship_front',
								src: getLink(state.data.citizenship_front),
							},
							{
								id: 'citizenship_back',
								src: getLink(state.data.citizenship_back),
							},
							{
								id: 'selfi_with_citizenship',
								src: getLink(state.data.selfi_with_citizenship),
							},
						]}
					/>
					<br />
					<h2>
						<b>Academic Report (Bachelor)</b>
					</h2>
					<div className="card">
						<div className="three-item">
							<p>
								<b>Board/University: </b>
								{state.data.bachelor_board}
							</p>
							<p>
								<b>Degree: </b>
								{state.data.bachelor_degree}
							</p>
							<p>
								<b>Duration: </b>
								{state.data.bachelor_duration}
							</p>
							<p>
								<b>Specialization: </b>
								{state.data.bachelor_specialization}
							</p>
							<p>
								<b>Grade: </b>
								{state.data.bachelor_grade}
							</p>
							<p>
								<b>Date of award: </b>
								{state.data.bachelor_doa}
							</p>
						</div>
					</div>
					<DocCarousel
						docs={[
							{
								id: 'bachelor_marksheet',
								src: getLink(state.data.bachelor_marksheet),
							},
							{
								id: 'bachelor_cc',
								src: getLink(state.data.bachelor_cc),
							},
							{
								id: 'bachelor_mc',
								src: getLink(state.data.bachelor_mc),
							},
							{
								id: 'tu_equivalence',
								src: getLink(state.data.tu_equivalence),
							},
						]}
					/>
					<br />
					<h2>
						<b>Academic Report (+2 / A level)</b>
					</h2>
					<div className="card">
						<div className="three-item">
							<p>
								<b>Board/University: </b>
								{state.data.plus2_board}
							</p>
							<p>
								<b>Degree: </b>
								{state.data.plus2_degree}
							</p>
							<p>
								<b>Duration: </b>
								{state.data.plus2_duration}
							</p>
							<p>
								<b>Specialization: </b>
								{state.data.plus2_specialization}
							</p>
							<p>
								<b>Grade: </b>
								{state.data.plus2_grade}
							</p>
							<p>
								<b>Date of award: </b>
								{state.data.plus2_doa}
							</p>
						</div>
					</div>
					<DocCarousel
						docs={[
							{
								id: 'plus2_marksheet',
								src: getLink(state.data.plus2_marksheet),
							},
							{
								id: 'plus2_cc',
								src: getLink(state.data.plus2_cc),
							},
						]}
					/>
					<br />
					<h2>
						<b>Academic Report (SLC)</b>
					</h2>
					<div className="card">
						<div className="three-item">
							<p>
								<b>Board/University: </b>
								{state.data.slc_board}
							</p>
							<p>
								<b>Degree: </b>
								{state.data.slc_degree}
							</p>
							<p>
								<b>Duration: </b>
								{state.data.slc_duration}
							</p>
							<p>
								<b>Specialization: </b>
								{state.data.slc_specialization}
							</p>
							<p>
								<b>Grade: </b>
								{state.data.slc_grade}
							</p>
							<p>
								<b>Date of award: </b>
								{state.data.slc_doa}
							</p>
						</div>
					</div>
					<DocCarousel
						docs={[
							{
								id: 'slc_marksheet',
								src: getLink(state.data.slc_marksheet),
							},
							{
								id: 'slc_cc',
								src: getLink(state.data.slc_cc),
							},
						]}
					/>
					<br />
					<h2>
						<b>Academic Report (Extra)</b>
					</h2>
					<div className="card">
						<div className="three-item">
							<p>
								<b>Board/University: </b>
								{state.data.extra_board}
							</p>
							<p>
								<b>Degree: </b>
								{state.data.extra_degree}
							</p>
							<p>
								<b>Duration: </b>
								{state.data.extra_duration}
							</p>
							<p>
								<b>Specialization: </b>
								{state.data.extra_specialization}
							</p>
							<p>
								<b>Grade: </b>
								{state.data.extra_grade}
							</p>
							<p>
								<b>Date of award: </b>
								{state.data.extra_doa}
							</p>
						</div>
					</div>
					<br />
					<h2>
						<b>Financial Support</b>
					</h2>
					<div className="card">
						{parse(state.data.financial_support || '')}
					</div>
					<br />
					<h2>
						<b>Referee (First)</b>
					</h2>
					<div className="card">
						<div className="two-item">
							<p>
								<b>Name: </b> {state.data.referee_one_name}
							</p>
							<p>
								<b>Phone: </b> {state.data.referee_one_phone}
							</p>
							<p>
								<b>Institution: </b>{' '}
								{state.data.referee_one_institution}
							</p>
							<p>
								<b>Position: </b>{' '}
								{state.data.referee_one_position}
							</p>
						</div>
					</div>
					<br />
					<h2>
						<b>Referee (Second)</b>
					</h2>
					<div className="card">
						<div className="two-item">
							<p>
								<b>Name: </b> {state.data.referee_two_name}
							</p>
							<p>
								<b>Phone: </b> {state.data.referee_two_phone}
							</p>
							<p>
								<b>Institution: </b>{' '}
								{state.data.referee_two_institution}
							</p>
							<p>
								<b>Position: </b>{' '}
								{state.data.referee_two_position}
							</p>
						</div>
					</div>
					<br />
					<h2>
						<b>Why SOMTU</b>
					</h2>
					<div className="card">
						{parse(state.data.why_somtu || '')}
					</div>
					<br />
					<h2>
						<b>Documents</b>
					</h2>
					<div className="card">
						<FileItem
							link={state.data.pp_photo}
							title="Passport Size Photo"
						/>
						<FileItem
							link={state.data.citizenship_front}
							title="Citizenship Front"
						/>
						<FileItem
							link={state.data.citizenship_back}
							title="Citizenship Back"
						/>
						<FileItem
							link={state.data.slc_marksheet}
							title="SLC Marksheet"
						/>
						<FileItem
							link={state.data.slc_cc}
							title="SLC Character Certificate"
						/>
						<FileItem
							link={state.data.plus2_marksheet}
							title="+2/A level Marksheet"
						/>
						<FileItem
							link={state.data.plus2_cc}
							title="+2/A level Character Certificate"
						/>
						<FileItem
							link={state.data.bachelor_marksheet}
							title="Bachelor Marksheet"
						/>
						<FileItem
							link={state.data.bachelor_cc}
							title="Bachelor Character Certificate"
						/>
						<FileItem
							link={state.data.bachelor_mc}
							title="Bachelor Migration Certificate"
						/>
						<FileItem
							link={state.data.selfi_with_citizenship}
							title="Selfie With Citizenship"
						/>
						<FileItem
							link={state.data.tu_equivalence}
							title="TU Equivalence"
						/>
					</div>
					<br />
					<h2>
						<b>Actions</b>
					</h2>
					<br />
					<Button onClick={onApprove} type="primary">
						Approve Form
					</Button>
					<div className="hgap"></div>
					<Button onClick={() => setShowReject(true)} type="danger">
						Reject Form
					</Button>
					<div className="hgap"></div>
					<Button onClick={onDelete} type="danger">
						Delete Form
					</Button>
					<br />
					<br />
					<br />
				</main>
			)}
		</div>
	)
}

const FileItem = ({ link, title }) => {
	const getLink = () => {
		if (!link) return '/pdf.png'
		const ext = link.substr(link.length - 3)
		if (ext === 'pdf' || ext === 'PDF') return '/pdf.png'
		return FILE_BASE_URL + link
	}
	if (!link) return null
	return (
		<div className="file-item">
			<img
				style={{
					width: 60,
					height: 60,
					objectFit: 'cover',
					borderRadius: 4,
				}}
				src={getLink()}
				alt=""
			/>
			<br />
			<a
				href={FILE_BASE_URL + link}
				rel="noopener noreferrer"
				target="_blank"
			>
				{title}
			</a>
		</div>
	)
}
