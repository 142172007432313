import React from "react";
import { Input, Form, Button } from "antd";
import "./login.scss";
import { execLogin } from "../../../api/adminApis";
import { notifySuccess, notifyError } from "../../../helper/notifiers";

export default function Login(props) {
  const [loading, setLoading] = React.useState(false);

  const doLogin = async (values) => {
    setLoading(true);
    try {
      const res = await execLogin(values);
      if (res.token) localStorage.setItem("token", res.token);
      if (res.user) localStorage.setItem("admin_data", JSON.stringify(res.user));
      notifySuccess(res.message);
      setLoading(false);
      props.history.push("/somtuadmin");
    } catch (err) {
      notifyError(err.message);
      setLoading(false);
    }
  };

  return (
    <section id="login">
      <div className="login-controls">
        <header>
          <div className="title">SOMAT Admin</div>
        </header>
        <Form onFinish={doLogin}>
          <Form.Item
            name="username"
            label="Username"
            className="full"
            rules={[
              {
                required: true,
                message: "Username is required",
              },
            ]}
          >
            <Input placeholder="eg. john_doe"></Input>
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            className="full"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
              () => ({
                validator(_, value) {
                  if (value && value.length > 4) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Password must be of atleast 5 character");
                },
              }),
            ]}
          >
            <Input.Password placeholder="eg. abcd1234"></Input.Password>
          </Form.Item>
          <br />
          <Button block htmlType="submit" type="primary" loading={loading}>
            Login
          </Button>
        </Form>
      </div>
    </section>
  );
}
