import React from "react";
import { Button, Pagination, Input } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { execGetAllVoucherRequest } from "../../../api/adminApis";
import { getStatusColor } from "../../../helper/getColor";
import Loading from "../../../components/Loading";
import queryString from "query-string";

export default function VoucherRequest() {
  const [state, setState] = React.useState({
    loading: true,
    data: null,
    error: false,
  });

  const [filter, setFilter] = React.useState({
    page: 1,
    per_page: 20,
    q: "",
  });

  const fetchUsers = async () => {
    setState({ data: null, error: false, loading: true });
    const stringifiedParams = queryString.stringify(filter);
    try {
      const res = await execGetAllVoucherRequest(stringifiedParams);
      setState({ loading: false, data: res, error: false });
    } catch (err) {
      setState({ loading: false, data: null, error: err });
    }
  };

  React.useEffect(() => {
    fetchUsers();
    //eslint-disable-next-line
  }, [filter]);

  return (
    <section id="contents">
      <br />
      <h1 className="title"> Entrance Form </h1>
      <br />
      <div className="flex jcsb ci">
        <Input.Search
          onChange={(e) => {
            if (!e.target.value) {
              setFilter({ ...filter, q: "" });
            }
          }}
          onSearch={(val) => setFilter({ ...filter, q: val })}
          placeholder="Search...."
          style={{ width: 250 }}
          allowClear
        />
        <Pagination
          onChange={(val) => {
            setFilter((pF) => ({ ...pF, page: val }));
          }}
          total={(state.data && state.data.total) || 0}
          current={(state.data && state.data.page) || 1}
          showSizeChanger
          pageSize={(state.data && state.data.per_page) || 20}
          onShowSizeChange={(val, perPage) => {
            console.log(val, perPage);
            setFilter((pF) => ({ ...pF, page: val, per_page: perPage }));
          }}
        />
      </div>
      <br />
      {state.loading && <Loading />}
      {state.error && <p style={{ color: "red" }}>{state.error.message}</p>}
      {state.data && state.data.forms.length > 0 && (
        <React.Fragment>
          <div className="contents-table">
            <table cellPadding="0" cellSpacing="0">
              <ContentTableHead />
              <tbody>
                {state.data.forms.map((item, index) => {
                  return <ContentTableItems key={item._id} {...item} sn={index + 1} />;
                })}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
    </section>
  );
}

const ContentTableHead = () => {
  return (
    <thead>
      <tr>
        <th style={{ maxWidth: "40px" }}>SN</th>
        <th>Name</th>
        <th>Email</th>
        <th>Citizenship Number</th>
        <th>Applying For</th>
        <th>Voucher Status</th>
        <th style={{ maxWidth: "200px" }}>Created At</th>
        <th style={{ maxWidth: "200px" }}>Updated At</th>
        <th style={{ maxWidth: "200px" }}>Actions</th>
      </tr>
    </thead>
  );
};

const ContentTableItems = (props) => {
  return (
    <tr>
      <td>{props.sn}</td>
      <td>
        {props.first_name} {props.middle_name} {props.surname}
      </td>
      <td>{props.email}</td>
      <td>{props.citizenship_no}</td>
      <td>{props.applying_for}</td>
      <td>
        <p style={{ color: getStatusColor(props.voucher_status) }}>{props.voucher_status}</p>
      </td>
      <td>{props.created_at}</td>
      <td>{props.updated_at}</td>
      <td className="actions">
        <Link to={"/somtuadmin/voucher_details/" + props._id}>
          <Button shape="circle" icon={<EyeOutlined />} size="small" type="primary" />
        </Link>
      </td>
    </tr>
  );
};
