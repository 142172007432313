import React from "react";
import { Route, Switch } from "react-router-dom";
import AdminHome from "./Admin/Home/Home";
import Login from "./Admin/Login/Login";

export default function AdminEnd({ match }) {
  return (
    <div>
      <Switch>
        <Route path={match.url + "/login"} exact component={Login} />
        <Route path={match.url + "/"} component={AdminHome} />
      </Switch>
    </div>
  );
}
