import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import UserEnd from "./pages/UserEnd";
import AdminEnd from "./pages/AdminEnd";
import ReactGA from "react-ga";

function App() {
  ReactGA.initialize("UA-161797190-1");
  ReactGA.pageview(window.location.pathname + window.location.search);

  const generateRandomNumber= () => {
    const rnn = localStorage.getItem("randomIdentifier");
    if(!rnn){
      const rn = `${Math.floor((Math.random()*1000) + 100)}somat${Date.now().toString().substr(-4)}`;
      localStorage.setItem("randomIdentifier", rn);
    }
  }

  useEffect(()=>{
    generateRandomNumber();
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/somtuadmin" component={AdminEnd} />
        <Route path="/" component={UserEnd} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
