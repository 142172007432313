import React from 'react'
export default class RotateIMG extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			rotation: 0,
		}

		this.rotate = this.rotate.bind(this)
		this.rotateleft = this.rotateleft.bind(this)
	}

	rotate() {
		let newRotation = this.state.rotation + 90
		if (newRotation >= 360) {
			newRotation = -360
		}
		this.setState({
			rotation: newRotation,
		})
	}

	rotateleft() {
		let newRotation = this.state.rotation - 90
		if (newRotation >= 360) {
			newRotation = -360
		}
		this.setState({
			rotation: newRotation,
		})
	}

	render() {
		const { rotation } = this.state
		return (
			<div>
				<img
					style={{
						transform: `rotate(${rotation}deg)`,
						// objectFit: 'scale-down',
					}}
					src={this.props.src}
					width="800"
					alt="dov"
				/>
				<input onClick={this.rotateleft} type="button" value="left" />
				<input onClick={this.rotate} type="button" value="right" />
			</div>
		)
	}
}
