import { axiosInstance } from "./axiosInstance";
import { BASE_URL } from "../configs";

export const execGetEntraceForm = (filter) => {
  return axiosInstance.get(BASE_URL + "/admin/v1/entrance_form?" + filter);
};

export const execGetAllVoucher = (filter) => {
  return axiosInstance.get(BASE_URL + "/admin/v1/voucher?" + filter);
};

export const execGetAllVoucherRequest = (filter) => {
  return axiosInstance.get(BASE_URL + "/admin/v1/voucher_request?" + filter);
};

export const execGetOneEntraceForm = (eId) => {
  return axiosInstance.get(BASE_URL + "/admin/v1/entrance_form/" + eId);
};

export const execUpdateEntraceForm = (eId, payload) => {
  return axiosInstance.patch(BASE_URL + "/admin/v1/entrance_form/" + eId, payload);
};

export const execUpdateVoucher = (eId, payload) => {
  return axiosInstance.patch(BASE_URL + "/admin/v1/voucher/" + eId, payload);
};

export const execLogin = (payload) => {
  return axiosInstance.post(BASE_URL + "/admin/v1/login", payload);
};

export const execGetPrograms = () => {
  return axiosInstance.get(BASE_URL + "/admin/v1/study_program");
};

export const execUpdatePrograms = (id, payload) => {
  return axiosInstance.patch(BASE_URL + "/admin/v1/study_program/" + id, payload);
};

export const execSendAdmitCard = (id, payload) => {
  return axiosInstance.patch(BASE_URL + "/admin/v1/admit_card/" + id, payload);
};

export const execUpdateForm = (id, payload) => {
  return axiosInstance.patch(BASE_URL + "/admin/v1/entrance_form_update/" + id, payload);
};

export const execGetCsv = () => {
  return axiosInstance.get(BASE_URL + "/admin/v1/getcsv");
};
