import React from "react";
import Loading from "../../../components/Loading";
import { FILE_BASE_URL } from "../../../configs";
import { Button, Form, Input, Modal } from "antd";
import { execCheckVoucherSubmit, execFileUpload, execVoucherSubmit } from "../../../api/apis";
import queryString from "query-string";
import SubPageHead from "../../../components/SubPageHead/SubPageHead";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import { notifyError, notifySuccess } from "../../../helper/notifiers";

const FormClosedCard = (props) => {
  return (
    <div className="success-card text center">
      <div className="check" style={{ background: "#f5222d" }}>
        <CloseOutlined />
      </div>
      <p className="message">
        <b>{props.message}</b>
      </p>
    </div>
  );
};

export default function SubmitVoucher({ history }) {
  const [statusLoading, setStatusLoading] = React.useState(false);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [form] = Form.useForm();
  const [url, setUrl] = React.useState("");

  const checkVoucher = async (payload) => {
    setStatusLoading(true);
    setCanSubmit(false);
    try {
      await execCheckVoucherSubmit(payload);
      setStatusLoading(false);
      setCanSubmit(true);
    } catch (err) {
      setStatusLoading(false);
      onError(err.message.includes("ObjectId") ? "Invalid input provided" : err.message);
    }
  };

  const onOk = async (val) => {
    const { query } = queryString.parseUrl(window.location.href);
    const { form_id, identification_number } = query;
    if (url.length < 3) {
      notifyError("Please upload voucher photo before submitting");
      return;
    }
    const payload = { voucher: { ...val, url }, identification_number, form_id };
    try {
      const res = await execVoucherSubmit(payload);
      notifySuccess(res.message);
      console.log(res);
      history.push("/");
    } catch (err) {
      notifyError(err.message);
    }
    console.log(payload);
  };

  const onError = (msg) => {
    Modal.info({
      title: " ",
      centered: true,
      icon: null,
      content: <FormClosedCard message={msg} />,
      okButtonProps: { style: { background: "#f5222d", borderColor: "#f5222d" } },
      onOk: () => {
        history.push("/");
      },
    });
  };

  React.useEffect(() => {
    const { query } = queryString.parseUrl(window.location.href);
    const { form_id, identification_number } = query;
    checkVoucher({ form_id, identification_number });
    //eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <SubPageHead title="Submit Voucher" />
      <div className="wrap">
        <br />
        {statusLoading && <Loading />}
        {canSubmit && (
          <main>
            <h1>
              <b>Voucher Sample</b>
            </h1>
            <br />
            <figure>
              <img style={{ maxHeight: "400px", maxWidth: "100%", borderRadius: 10 }} src="/vsample.png" alt="" />
            </figure>
            <br />
            <h1>
              <b>Enter Voucher Details</b>
            </h1>
            <br />
            <div className="fields">
              <Form form={form} onFinish={onOk}>
                <div className="three-item">
                  <Form.Item
                    label="Bank Name"
                    className="full"
                    name="bank"
                    rules={[
                      {
                        required: true,
                        message: "Bank name is required",
                      },
                    ]}
                  >
                    <Input placeholder="eg. Global IME Bank" />
                  </Form.Item>

                  <Form.Item
                    label="Branch Name"
                    className="full"
                    name="branch"
                    rules={[
                      {
                        required: true,
                        message: "Branch name is required",
                      },
                    ]}
                  >
                    <Input placeholder="eg. Koteshowr Branch" />
                  </Form.Item>

                  <Form.Item
                    label="Payment Amount"
                    className="full"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Payment amount is required",
                      },
                    ]}
                  >
                    <Input prefix="Rs." placeholder="2500" />
                  </Form.Item>
                  <Form.Item
                    label="Transaction ID"
                    className="full"
                    name="number"
                    rules={[
                      {
                        required: true,
                        message: "Transaction ID is required",
                      },
                    ]}
                  >
                    <Input prefix="TS DC" placeholder="29895" />
                  </Form.Item>

                  <Form.Item
                    label="Paid By"
                    className="full"
                    name="paid_by"
                    rules={[
                      {
                        required: true,
                        message: "Paid by is required",
                      },
                    ]}
                  >
                    <Input placeholder="i.e. Applicant’s Name" />
                  </Form.Item>

                  <Form.Item
                    label="Date of payment (BS) | YYYY-MM-DD"
                    className="full"
                    name="date_of_payment"
                    required
                    rules={[
                      () => ({
                        validator(_, value) {
                          const checkFormat = value.split("-");
                          if (checkFormat.length > 2) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Date of payment must be valid");
                        },
                      }),
                    ]}
                  >
                    <Input placeholder="eg. 2077-04-20" />
                  </Form.Item>
                </div>
                <br />

                <div>
                  <UploadItem title="Clear Voucher Photo" onUpload={(val) => setUrl(val)} />
                </div>
                <br />

                <Button htmlType="submit" type="primary">
                  Submit Voucher
                </Button>
              </Form>
            </div>
            <br />
            <br />
            <br />
          </main>
        )}
      </div>
    </React.Fragment>
  );
}

const UploadItem = ({ title, onUpload }) => {
  const fileRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const rnn = localStorage.getItem("randomIdentifier") || Date.now().toString().substr(-4);

  const uploadFile = async () => {
    const file = fileRef.current.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_for", "voucher_bill");
    formData.append("file_key", rnn);


    setLoading(true);
    setData(null);
    try {
      const res = await execFileUpload(formData);
      setData(res);
      onUpload(res.url);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      notifyError(err.message || "Looks like you have got slow connection, please try again");
    }
  };

  return (
    <div className="upload-item text center" style={{ marginLeft: 0 }}>
      <input type="file" onChange={() => uploadFile()} ref={fileRef} style={{ display: "none" }} />
      {data && (
        <React.Fragment>
          <aside>
            <a href={FILE_BASE_URL + data.url} target="_blank" rel="noopener noreferrer">
              <img style={{ width: 60 }} src={"/" + data.ext + ".png"} alt="" />
              <p style={{ marginTop: 7, fontSize: 12 }}>
                <b>{data.name}</b>
              </p>
            </a>
          </aside>
          <br />
        </React.Fragment>
      )}
      <p>
        <b>{title}</b>
      </p>
      <br />
      <Button onClick={() => fileRef.current.click()} shape="round" loading={loading} icon={<UploadOutlined />} type="primary"></Button>
    </div>
  );
};
