import React from "react";
import SubPageHead from "../../components/SubPageHead/SubPageHead";

export default function About() {
  return (
    <div>
      <SubPageHead title="About us" />
      <br />
      <div className="wrap text">
        <p style={{ lineHeight: "25px" }}>The School of Management (SOMTU) is an autonomous institution formed by Tribhuvan University under the Faculty of Management. The SOMTU envisages implementing its programs with a professioal touch with the huge support base of academically qualified faculty members with wide exposures and experience. The operation of the SOMTU is governed by the Working Procedure Regulaltions, 2066 that gives the institution a fully autonomous status. The SOMTU Governing Board is the apex body that is authorized that is authorized to determine all academic, administrative, and financial policies of the School.</p>
        <br />
        <br />
        <a href="https://somatmbastorage.s3.ap-south-1.amazonaws.com/somat.pdf" target="_blank" rel="noopener noreferrer">
          <button className="apply-now">Download Brochure</button>
        </a>
      </div>
    </div>
  );
}
