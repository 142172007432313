import React from "react";
import "./_home.scss";
import TopNavBar from "./TopNavBar";
import { Switch, Route } from "react-router-dom";
import HomeSideNav from "./HomeSideNav";
import EntranceForm from "../EntranceForm/EntranceForm";
import EntranceFormDetails from "../EntranceFormDetails/EntranceFormDetails";
import FormStatus from "../FormStatus/FormStatus";
import VoucherRequest from "../VoucherRequest/VoucherRequest";
import VoucherDetails from "../VoucherDetails/VoucherDetails";
import VoucherStatus from "../VoucherRequest/VoucherStatus";
import EntranceFormEdit from "../EntranceFormEdit/EntranceFormEdit";

export default function Home(props) {
  const [sliderCollapsed, setSliderCollapsed] = React.useState(false);
  const token = localStorage.getItem("token");

  if (!token) {
    props.history.push("/somtuadmin/login");
    return null;
  }

  return (
    <section id="dashboard">
      <div className="flex">
        <div className="dashboard-left">
          <HomeSideNav sliderCollapsed={sliderCollapsed} setSliderCollapsed={setSliderCollapsed} />
        </div>
        <div className="dashboard-right" style={{ height: "100vh", overflowY: "auto" }}>
          <TopNavBar history={props.history} sliderCollapsed={sliderCollapsed} setSliderCollapsed={setSliderCollapsed} />
          <div className="awrap">
            <Switch>
              <Route path={props.match.url + "/"} exact component={EntranceForm} />
              <Route path={props.match.url + "/vouchers"} exact component={VoucherRequest} />
              <Route path={props.match.url + "/voucher_status"} exact component={VoucherStatus} />
              <Route path={props.match.url + "/form_status"} exact component={FormStatus} />
              <Route path={props.match.url + "/entrance_form/:id"} exact component={EntranceFormDetails} />
              <Route path={props.match.url + "/entrance_form_edit/:id"} exact component={EntranceFormEdit} />
              <Route path={props.match.url + "/voucher_details/:id"} exact component={VoucherDetails} />
            </Switch>
          </div>
        </div>
      </div>
    </section>
  );
}
