import React from "react";
import { Button, Form, Input } from "antd";

export default function AcademicRecord({ onNext, onPrevious, inputs }) {
  const [form] = Form.useForm();
  const onOk = (val) => {
    onNext(val);
  };

  React.useEffect(() => {
    form.setFieldsValue({ ...inputs });
    // eslint-disable-next-line
  }, [inputs]);

  return (
    <Form onFinish={onOk} form={form}>
      <header>
        <h1>
          <b>Bachelor</b>
        </h1>
      </header>
      <div className="three-item">
        <Form.Item
          label="Board/University"
          className="full"
          name="bachelor_board"
          rules={[
            {
              required: true,
              message: "Board/University is required",
            },
          ]}
        >
          <Input placeholder="eg. Tribhuwan University" />
        </Form.Item>
        <Form.Item
          label="Degree"
          className="full"
          name="bachelor_degree"
          rules={[
            {
              required: true,
              message: "Degree is required",
            },
          ]}
        >
          <Input placeholder="eg. Degree Name" />
        </Form.Item>
        <Form.Item
          label="Duration"
          className="full"
          name="bachelor_duration"
          rules={[
            {
              required: true,
              message: "Duration is required",
            },
          ]}
        >
          <Input placeholder="eg. 4 years" />
        </Form.Item>
      </div>
      <div className="three-item">
        <Form.Item
          label="Specialization Area"
          className="full"
          name="bachelor_specialization"
          rules={[
            {
              required: true,
              message: "Specialization area is required",
            },
          ]}
        >
          <Input placeholder="eg. Finance" />
        </Form.Item>
        <Form.Item
          label="Division/Grade"
          className="full"
          name="bachelor_grade"
          rules={[
            {
              required: true,
              message: "Division/grade is required",
            },
          ]}
        >
          <Input placeholder="eg. 3.99" />
        </Form.Item>
        <Form.Item
          label="Date of award of degree"
          className="full"
          name="bachelor_doa"
          rules={[
            {
              required: true,
              message: "Date of award is required",
            },
          ]}
        >
          <Input placeholder="eg. 2010-04-25 or NA" />
        </Form.Item>
      </div>
      <br />
      <header>
        <h1>
          <b>+2/ A-level or Equivalent</b>
        </h1>
      </header>
      <div className="three-item">
        <Form.Item
          label="Board/University"
          className="full"
          name="plus2_board"
          rules={[
            {
              required: true,
              message: "Board/University is required",
            },
          ]}
        >
          <Input placeholder="eg. Tribhuwan University" />
        </Form.Item>
        <Form.Item
          label="Degree"
          className="full"
          name="plus2_degree"
          rules={[
            {
              required: true,
              message: "Degree is required",
            },
          ]}
        >
          <Input placeholder="eg. Degree Name" />
        </Form.Item>
        <Form.Item
          label="Duration"
          className="full"
          name="plus2_duration"
          rules={[
            {
              required: true,
              message: "Duration is required",
            },
          ]}
        >
          <Input placeholder="eg. 4 years" />
        </Form.Item>
      </div>
      <div className="three-item">
        <Form.Item label="Specialization Area" className="full" name="plus2_specialization">
          <Input placeholder="eg. Finance" />
        </Form.Item>
        <Form.Item
          label="Division/Grade"
          className="full"
          name="plus2_grade"
          rules={[
            {
              required: true,
              message: "Division/Grade is required",
            },
          ]}
        >
          <Input placeholder="eg. 3.99" />
        </Form.Item>
        <Form.Item
          label="Date of award of degree"
          className="full"
          name="plus2_doa"
          rules={[
            {
              required: true,
              message: "Date of award is required",
            },
          ]}
        >
          <Input placeholder="eg. 2010-04-25" />
        </Form.Item>
      </div>

      <br />
      <header>
        <h1>
          <b>SLC or Equivalent</b>
        </h1>
      </header>
      <div className="three-item">
        <Form.Item
          label="Board/University"
          className="full"
          name="slc_board"
          rules={[
            {
              required: true,
              message: "Board/University is required",
            },
          ]}
        >
          <Input placeholder="eg. Tribhuwan University" />
        </Form.Item>
        <Form.Item
          label="Degree"
          className="full"
          name="slc_degree"
          rules={[
            {
              required: true,
              message: "Degree is required",
            },
          ]}
        >
          <Input placeholder="eg. Degree Name" />
        </Form.Item>
        <Form.Item label="Duration" className="full" name="slc_duration">
          <Input placeholder="eg. 4 years" />
        </Form.Item>
      </div>
      <div className="three-item">
        <Form.Item label="Specialization Area" className="full" name="slc_specialization">
          <Input placeholder="eg. Finance" />
        </Form.Item>
        <Form.Item
          label="Division/Grade"
          className="full"
          name="slc_grade"
          rules={[
            {
              required: true,
              message: "Division/Grade is required",
            },
          ]}
        >
          <Input placeholder="eg. 3.99" />
        </Form.Item>
        <Form.Item
          label="Date of award of degree"
          className="full"
          name="slc_doa"
          rules={[
            {
              required: true,
              message: "Date of award is required",
            },
          ]}
        >
          <Input placeholder="eg. 2010-04-25" />
        </Form.Item>
      </div>

      <br />
      <header>
        <h1>
          <b>Extra</b>
        </h1>
      </header>
      <div className="three-item">
        <Form.Item label="Board/University" className="full" name="extra_board">
          <Input placeholder="eg. Tribhuwan University" />
        </Form.Item>
        <Form.Item label="Degree" className="full" name="extra_degree">
          <Input placeholder="eg. Degree Name" />
        </Form.Item>
        <Form.Item label="Duration" className="full" name="extra_duration">
          <Input placeholder="eg. 4 years" />
        </Form.Item>
      </div>
      <div className="three-item">
        <Form.Item label="Specialization Area" className="full" name="extra_specialization">
          <Input placeholder="eg. Finance" />
        </Form.Item>
        <Form.Item label="Division/Grade" className="full" name="extra_grade">
          <Input placeholder="eg. 3.99" />
        </Form.Item>
        <Form.Item label="Date of award of degree" className="full" name="extra_doa">
          <Input placeholder="eg. 2010-04-25" />
        </Form.Item>
      </div>
      <br />
      <div className="flex ci">
        <Button onClick={() => onPrevious(form.getFieldsValue())} style={{ width: 200 }}>
          Previous
        </Button>
        <div className="hgap"></div>
        <Button htmlType="submit" style={{ width: 200 }} type="primary">
          Next
        </Button>
      </div>
    </Form>
  );
}
