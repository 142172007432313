import React from 'react'
import NavBar from '../components/NavBar/NavBar'
import { Switch, Route } from 'react-router-dom'
import HomePage from './User/HomePage'
import EntryForm from './User/EntryForm/EntryForm'
import ReadFormStatus from './User/ReadMyForm/ReadMyForm'
import SubmitVoucher from './User/SubmitVoucher/SubmitVoucher'
import Rules from './User/Rules'
import About from './User/About'
import Notices from './User/Notices'

export default function UserEnd() {
	return (
		<div className="App">
			<NavBar />
			<Switch>
				<Route path="/" exact component={HomePage} />
				<Route path="/rules" exact component={Rules} />
				<Route path="/about" exact component={About} />
				<Route path="/notices" exact component={Notices} />
				<Route path="/entry_form" exact component={EntryForm} />
				<Route path="/form_status" exact component={ReadFormStatus} />
				<Route path="/submit_voucher" exact component={SubmitVoucher} />
			</Switch>
		</div>
	)
}
