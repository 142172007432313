import React from 'react'
import 'antd/dist/antd.css'
import { Carousel } from 'antd'
import RotateIMG from './ImageRotate'

const rex = new RegExp(/\.([0-9a-z]+)(?:[?#]|$)/i)

export default function DocCarousel({ docs }) {
	console.log(docs)
	return (
		<Carousel>
			{docs.map(doc => {
				if (!doc.src) {
					return null
				} else if (doc.src.match(rex)[1] !== 'pdf') {
					return <RotateIMG key={doc.id} src={doc.src} />
				} else {
					return (
						<div key={doc.id}>
							<iframe
								title="doc"
								src={doc.src}
								height="500px"
								width="80%"
								scrolling="auto"
							></iframe>
						</div>
					)
				}
			})}
		</Carousel>
	)
}
