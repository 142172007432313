import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import React from "react";

export const BreadCrumbBuilder = ({ data }) => {
  return (
    <Breadcrumb>
      {data.map((item, index) => {
        return (
          <Breadcrumb.Item key={index}>
            {item.goto && <Link to={item.goto}>{item.text}</Link>}
            {!item.goto && <React.Fragment>{item.text}</React.Fragment>}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};
